<template>
<div class="page404">
  <v-container class="text-center">
    <h1 class="mt-10 mb-5 fg-color-yellow">
      La page que vous recherchez n'existe plus.
    </h1>
  </v-container>
</div>
</template>

<script>

export default {
  name: "page404",
  components: {},
  mounted() {
    let me=this;
    setTimeout(function(){
      //console.log("404",me.$router.currentRoute);
      me.$router.push("/");
    },3000);
  }
}
</script>
