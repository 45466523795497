<template>
  <div class="page">
    <component
        v-if="$layout.currentPage.uid"
        :is="$layout.currentPage.pageComponentName"
        :record="$layout.currentPage">
    </component>
    <page404 v-else/>
    <record-watcher
        v-if="$layout.currentPage.uid && $db.userAdmin"
        :record="$layout.currentPage"/>

  </div>
</template>
<script>
import Page404 from "@/views/page-404";
import RecordWatcher from "@/views/record-watcher";
export default {
  name: 'PageUid',
  components: {
    RecordWatcher,
    Page404
  },
  mounted() {
    console.log("page uid mounted");
  },
  computed:{
    /**
     *
     * @return {DbRecord|null}
     */
    record(){
      return this.$db.getByUid(this.$layout.currentUid);
    },
  }
}
</script>
