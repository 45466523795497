<template>
<div v-if="$db.userAdmin" :watch-uid="record.uid">
  <!--record-watcher-->
</div>
</template>

<script>
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";

export default {
  name: "record-watcher",
  mixins:[RecordEditMixin],
}
</script>