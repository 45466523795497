export default {
    props: {
        "record":{
            type:Object
        },
    },
    computed:{
        /**
         * Le record mais spécialement typé DbRecord pour l'autocomplétions de code
         * @return {DbRecord}
         */
        rec(){
            return this.record;
        },
        /**
         * Le record mais spécialement typé DbRecordFile pour l'autocomplétions de code
         * @return {FileRecord}
         */
        recFile(){
            return this.record;
        }
    },
    watch:{
        record: {
            handler () {
                if(this.rec.id && this.$db.userAdmin){
                    this.rec.meta.modified=true;
                }
            },
            deep: true,
        },
    }
}